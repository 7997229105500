import axios from "axios";
import _ from "lodash";
import moment from "moment/moment";
import { AlertStateInterface } from "interfaces/alert";
import ApiError from "api/ApiError";
import ValidationService from "services/validation/ValidationService";

export function getValue(value: any): any {
  return typeof value === "function" ? value() : value;
}

export function getEnv(variable: string, defaultValue: any = null): any {
  if (!variable.hasOwnProperty("REACT_APP")) {
    variable = "REACT_APP_" + variable;
  }

  if (!process.env.hasOwnProperty(variable)) {
    return getValue(defaultValue);
  }

  const value = process.env[variable];

  if (typeof value !== "undefined") {
    switch (value.toLowerCase()) {
      case "true":
      case "(true)":
        return true;
      case "false":
      case "(false)":
        return false;
      case "empty":
      case "(empty)":
        return "";
      case "null":
      case "(null)":
        return null;
      default:
        break;
    }
  }

  return value;
}

export function createQueryString(data: Object): string {
  return Object.entries(data)
    .map(([key, value]) => key + "=" + value)
    .join("&");
}

export function safeJsonStringify(payload: any): string {
  if (typeof payload == "string") {
    return payload;
  }
  return JSON.stringify(payload);
}

export function getResponseData(response: any) {
  const data = _.get(response, "data");

  if (!(data !== null && typeof data === "object")) {
    throw new ApiError({
      message: `MALFORMED_RESPONSE "${data}"`,
      // TODO
      // ref: config.sessionId,
    });
  }
  return data;
}

export function getErrorData(exception: any): any {
  const resp = _.get(exception, "response.data", {});

  if (!resp.error) {
    return exception;
  }

  return new ApiError(resp.error);
}

export function getCurrentPage(): string {
  return window.location.pathname.substring(1);
}

export function userAgentDevice() {
  // TODO: check the actual user agent?
  const width = window.innerWidth;
  const height = window.innerHeight;
  if (width <= 768 || height <= 768) {
    return `MOBILE (${width},${height})`;
  } else {
    return `DESKTOP (${width},${height})`;
  }
}

export function URLToObject(url: string) {
  let request: any = {};
  const pairs = url.substring(url.indexOf("?") + 1).split("&");

  if (pairs.length > 0 && pairs[0] !== "") {
    for (const element of pairs) {
      const pair = element.split("=");
      request[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
    }
  }

  return request;
}

export function getFormattedFqtvPrograms(fqtvPrograms: any[]): object {
  let formattedFqtvPrograms = {};

  fqtvPrograms.forEach((fqtvProgram: any) => {
    let obj: any = {};
    obj[fqtvProgram.code] = fqtvProgram.name;
    formattedFqtvPrograms = _.extend(formattedFqtvPrograms, obj);
  });

  if (fqtvPrograms) {
    return formattedFqtvPrograms;
  } else {
    return {};
  }
}

export function getMessageType(messages: any): string {
  let messageKeys = _.keys(messages),
    type = "success";
  if (_.includes(messageKeys, "error")) {
    type = "error";
  } else if (_.includes(messageKeys, "warning")) {
    type = "warning";
  }

  return type;
}

export async function fetch(url: string, init: object = { method: "get" }) {
  let result = null;
  const headers = _.extend(_.get(init, "headers", {}), {
    "User-Agent-Device": userAgentDevice(),
  });
  const req = { url, ...init, headers };

  try {
    result = await tryAtMost(() => axios(req), {
      canRetry: (err: Error) => err.message === "Network Error",
    });
    return result;
  } catch (exception) {
    throw getErrorData(exception);
  }
}

/**
 * Async sleep
 * @see https://stackoverflow.com/questions/951021/what-is-the-javascript-version-of-sleep
 */
export function sleep(ms: number): Promise<any> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

/**
 * Attempts to make an async request with retries and backoff
 * @see https://gist.github.com/briancavalier/842626
 */
export async function tryAtMost(
  asyncReq: Function,
  {
    // options
    maxRetries = 5,
    retryInterval = 250,
    maxRetryInterval = 1000,
    onFail = _.noop,
    onReady = _.noop,
    canRetry = _.constant(true),
  }: TryAtMostOptions = {},
): Promise<any> {
  let retries = 0;
  while (retries++ < maxRetries) {
    try {
      const result = await asyncReq();
      onReady({ retries, result });
      return result;
    } catch (error: any) {
      onFail({ retries, maxRetries, error });
      if (retries >= maxRetries || !canRetry(error)) {
        const errorCode = error.response?.status;
        if (errorCode && [500, 423, 424].includes(errorCode)) {
          const page = {
            "500": "internal",
            "423": "release_in_progress",
            "424": "no_promotion"
          }[errorCode as string];
          window.location.assign(
            `${window.location.protocol}//${window.location.host}/jsonapi/${page}/${getCustomerCode().toUpperCase()}`,
          );
        }
        throw error;
      }
      const interval = Math.min(maxRetryInterval, retryInterval * retries);
      await sleep(interval);
    }
  }
}

export function getCustomerCode() {
  return getEnv("CUSTOMER_SOLUTION", "DEF").toLowerCase();
}

export function getParentCustomerCode() {
  return getEnv("PARENT_CUSTOMER_SOLUTION", "DEF").toLowerCase();
}

export function getPoolImagesResourceUrl(resource: string): string {
  let bffApiUrl = getEnv("BFF_API_URL", "/");

  if (!bffApiUrl.endsWith("/")) {
    bffApiUrl = `${bffApiUrl}/`;
  }

  return `${bffApiUrl}poolimages/${resource}`;
}

export function validateFields(
  model: any,
  validator: ValidationService,
  isPaxValidation?: boolean,
): AlertStateInterface {
  const validationError: AlertStateInterface = {};
  _.each(model, (value, key: string) => {
    const validation = validator.validateFast({ [key]: value }, model);
    if (validation[key]) {
      const errorName = isPaxValidation
        ? `${model.paxType}_${key}_${model.pax_no}`
        : `${key}`;
      validationError[errorName] = {
        message: validation[key],
        replacements: validator.replacements[key][validation.rule],
      };
    }
  });
  return validationError;
}

export function mapObject<T>(payload: any, keys: (keyof T)[]): T {
  let result: Partial<T> = {};
  keys.forEach((key) => {
    if (key in payload) {
      result[key] = payload[key];
    }
  });
  return result as T;
}

export function getBrowserTime() {
  try {
    return moment().format("YYYY-MM-DDThh:mm:ssZ");
  } catch (e) {
    return null;
  }
}

export function getConfiguration<T>(configuration: { [key: string]: T }): T {
  const customerCode = getCustomerCode();
  const parentCustomerCode = getParentCustomerCode();
  if (configuration[customerCode]) {
    return configuration[customerCode] as T;
  }
  if(configuration[parentCustomerCode]){
    return configuration[parentCustomerCode] as T;
  }
  return configuration["default"] as T;
}

export function extendOrderedArray<T>(
  array: T[],
  newItem: T,
  sortingField: keyof T,
) {
  const newArray = array.map((item) => {
    if (item[sortingField] >= newItem[sortingField]) {
      return {
        ...item,
        [sortingField]: (item[sortingField] as number) + 1,
      };
    }
    return item;
  });
  newArray.push(newItem);
  return newArray.sort(
    (a, b) => (a[sortingField] as number) - (b[sortingField] as number),
  );
}

export function overrideArrayElements<T>(
  array: T[],
  items: { elements: Partial<T>; index: number }[],
) {
  return array.map((item, index) => {
    const overrideElement = items.find((item) => item.index === index);
    if (overrideElement) {
      return {
        ...item,
        ...overrideElement.elements,
      };
    }
    return item;
  });
}

interface TryAtMostOptions {
  maxRetries?: number;
  retryInterval?: number;
  maxRetryInterval?: number;
  onFail?: Function;
  onReady?: Function;
  canRetry?: Function;
}
